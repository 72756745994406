import type { LocationQuery, LocationQueryValue } from 'vue-router'

interface SignupModel {
  email?: string
  restaurant: RestaurantParams | undefined
  plan?: string
}

export interface RestaurantParams {
  name?: null | string
  address?: null | string
  city?: null | string
  region?: null | string
  country?: null | string
  postalCode?: null | string
  streetNumber?: null | string
  route?: null | string
  coordinates_lat?: null | string
  coordinates_lng?: null | string
}

type NormalizedParams = Record<string, LocationQueryValue>

export function getSignupParams(signupData: SignupModel, additionalParams?: Record<string, string>) {
  const { restaurant, email, plan } = signupData

  const searchParams = new URLSearchParams(additionalParams)

  const fields = [
    { key: 'email', value: email },
    { key: 'plan', value: plan },
    { key: 'name', value: restaurant?.name },
    { key: 'address', value: restaurant?.address },
    { key: 'city', value: restaurant?.city },
    { key: 'region', value: restaurant?.region },
    { key: 'country', value: restaurant?.country },
    { key: 'postalCode', value: restaurant?.postalCode },
    { key: 'streetNumber', value: restaurant?.streetNumber },
    { key: 'route', value: restaurant?.route },
    { key: 'coordinates_lat', value: restaurant?.coordinates_lat },
    { key: 'coordinates_lng', value: restaurant?.coordinates_lng },
  ]

  fields.forEach((field) => {
    if (field.value) {
      searchParams.append(field.key, field.value)
    }
  })

  return searchParams.toString()
}

export function normalizeQueryParams(params: LocationQuery) {
  const paramsEntries = Object.entries(params)
  return paramsEntries.reduce<NormalizedParams>(
    (prev, [key, value]) => {
      const newValue = Array.isArray(value)
        ? value.filter(val => val).join(', ')
        : value

      return {
        ...prev,
        [key]: newValue,
      }
    },
    {},
  )
}

export function getCoordinatesFromNormalizedParams(params: NormalizedParams) {
  if (!params.coordinates_lat || !params.coordinates_lng) {
    return
  }

  const lat = Number(params.coordinates_lat)
  const lng = Number(params.coordinates_lng)

  return { lat, lng }
}
